.printDetail .ant-card-head {
    background-color: #4f4f4f;
    color: #fff;
    height: 10px;
    font-size: 14px;
}
.printDetail .ant-card-head .ant-card-head-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* font-size: 14px; */
    height: 50px;
}
.reportPMActivity:nth-of-type(odd) {
    background-color: #f2f2f2;
}
.reportPMActivity{
    padding: 7px;
}
.printDetail{
    border: 0px;
}
.sigBox{
    height: 5vw;
}
.printDetail .ant-card-body{
    font-size: 12px;
}
  
@media only screen and (max-width: 700px) {
    .printDetail .ant-card-head {
        background-color: #4f4f4f;
        color: #fff;
        font-size: 2.5vw;
    }
    .printDetail .ant-card-body{
        font-size: 2vw;
    }
    .printDetail{
        font-size: 2vw;
    }
    .sigBox{
        height: 10vw;
    }
  }
  