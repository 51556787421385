.dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }
  
  .dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row-reverse;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 180px;
    }
  }
  
  .save__controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 4;
    font-size: 12px;
  }
  
  .save__controls button {
    margin-left: 5px;
  }
  
  .targetHandleStyle {
    background: #fff; 
    padding: 16px; 
    border-radius: 5px; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 30vw;
  }

  
  .targetHandleStyle .ant-card-body {
    padding: 0px;
  }

  .workflowSidebar {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    height: 45px;
    padding: 10px;
    padding-left: 10px;
  }
  
  .dotForWorkflowIcon {
    height: 25px;
    width: 25px;
    border-radius: 50%;
  }

  .workFlowIcon {
    height: 20px;
    width: 20px;
    padding-left: 5px;
  }

  .react-flow__handle.connectable{
    cursor: crosshair;
    width: 15px;
    height: 15px;
    z-index: 1;
  }

  .ant-table-filter-dropdown-btns {
    margin-top: 10px;
  }
  