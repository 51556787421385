    .ant-card.ant-card-bordered.card-back>.ant-card-head {
        background-color: #E0E0E0 !important;
        color: #323276 !important;
        padding: 15px;
        font-size: 16px;
    }

    .striped:nth-of-type(odd) {
        background-color: #f2f2f2;
    }

    .striped {
        padding: 10px;
    }

    .herder-form {
        padding: 20px;
        text-align: center;
        font-size: 18px;
    }

    .herder-export {
        font-size: 15px;
    }

    .box-signature {
        background-color: #f2f2f2;
        padding: 15px;
    }

    .box-border-Main {
        border: 1px solid #bfbfbf;
        padding: 1%;
    }

    tr.ant-table-expanded-row {
        /* background-color: #f2f2f2; */
    }

    .ant-steps-item-process .ant-steps-item-icon {
        background-color: #FA541C !important;
        color: #fff !important;
        border-color: #FA541C !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
        border-color: #FA541C;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
        color: #FA541C !important;
    }

    .feck-cancel {
        color: red;
    }

    .Box-Picture {
        padding-Top: 5px;
        border-bottom: 2px solid #F0F0F0;
        margin-Top: 10px;
        margin-bottom: 40px;
        height: 280px;
        overflow-y: scroll;
    }

    .Box-Picture::-webkit-scrollbar {
        width: 5px;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
    }

    /* Track */

    .Box-Picture::-webkit-scrollbar-track {
        background: rgba(242, 242, 242, 1);
        border-radius: 6px;
    }

    /* Handle */

    .Box-Picture::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.15);
        border-radius: 6px;
    }

    /* Handle on hover */

    .Box-Picture::-webkit-scrollbar-thumb:hover {
        background: #888;
        border-radius: 6px;
    }

    .custompicture .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding-right: 10px;
        border: '2px solid red'
    }

    .custompicturetest .ant-upload.ant-upload-select-picture-card {
        border: 1px solid #EB5757;
        ;
    }

    .picture-upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 8px 8px 0;
        width: 104px;
        border: 1px solid rgba(224, 224, 224, 1)
    }

    .truncate-text {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .truncate-text1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
    }

    .error-border {
        border: 2px solid red;
    }

    .Box-Wrap {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
    }

    .sync-pm-picture-frame {
        border: 1px solid #D9D9D9;
        padding: 13px;
        height: 130px;
        width: 100%;
        border-radius: 4px;
        position: relative;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .sync-pm-checkbox {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 3;
    }

    .sync-pm-img {
        object-fit: cover;
        height: 100%;
        border-radius: 2px;
    }

    .sync-pm-img-div {
        height: 100%;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sync-pm-img-div:hover {
        opacity: 0.8;
        height: 100%;
        width: 100%;
        z-index: 1;
        filter: brightness(90%);
    }

    .syncPM-field-box {
        border-radius: 7px;
        background-color: #fff;
    }

    .sync-pm-picture-frame:hover, .sync-pm-picture-frame-selected:hover {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    .sync-pm-picture-frame-selected {
        border: 1px solid #FA541C;
        padding: 13px;
        height: 130px;
        width: 130px;
        border-radius: 4px;
        position: relative;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    