body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Sarabun', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: inherit !important;
}

@font-face {
  font-family: 'Sarabun';
  src: local('Sarabun'), url(./assets/fonts/Sarabun-Regular.ttf) format('truetype');
}
