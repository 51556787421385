.HerderText {
    /*font-family: 'Sarabun';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #323276;
}

.TextLimitUser {
    /*font-family: 'Sarabun';*/
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #323276;
}

.DescText {
    font-weight: 400;
    font-size: 13px;
    color: #4F4F4F;
}

.My-card {
    border: 1px solid #e8e8e8;
    padding: 20px;
    height: 100%;
}

.TextRed {
    color: red;
}

.Box {
    border: 1px solid #E5E5E5;
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
    padding: 3%;
    margin: 15px;
}

.BoxLimitUser {
    padding: 10px;
    background: rgb(224 227 231 / 20%);
    border-radius: 10px;
    margin: 0px 5px;
    border: 1px solid #01B8E3;
}

.position-card {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.ant-carousel .slick-slide {
    text-align: center;
    height: 280px;
    line-height: 160px;
    overflow: hidden;
}

.my-body-card {
    text-align: center;
    color: #323276;
}

.ant-card.mycardtest.ant-card-bordered.ant-card-hoverable {
    border-radius: 10px;
}

.mycardtest{
    border-radius: 10px !important;
}

.card-service-package-active {
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 5px;
}

.card-service-package-InActive {
    background: #F2F2F2;
    border: 1px solid #BDBDBD;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 5px;
}

.mart5 {
    margin-top: 5px;
}