@import '~antd/dist/antd.css';

div {
    color: #323276;
    font-size: 13px;
}

img {
    max-width: 100%;
}

iframe {
    display: none;
}

iframe.iframe-display {
    display: block;
}

.btn-status {
    margin-top: 45px;
    width: '100%' !important;
    overflow: hidden;
}

.main-title,
.ant-drawer-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #323276 !important;
}

.sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    color: #323276 !important;
}

.content,
.ant-form label,
.ant-checkbox-wrapper {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #323276 !important;
}

.ant-tag {
    font-size: 13px;
}

.ant-input[disabled]:hover,
.ant-input[disabled],
.ant-select-disabled .ant-select-selection,
.ant-input-number-disabled .ant-input-number-input,
.addCriteriaButton.ant-btn[disabled],
.ant-time-picker-input[disabled] {
    border: none;
    color: #8C8C8C !important;
    background-color: #f5f5f5;
}

.ant-form-explain,
.ant-form-extra {
    min-height: 0px;
}

.ant-table-column-has-actions .ant-table-column-has-filters .ant-table-row-cell-break-word:hover {
    background-color: #f5f5f5;
}

.ant-table-row-cell-break-word {
    word-break: inherit;
}

.ticketRelateWo .ant-form-item-label {
    text-align: start !important;
}

.login-form {
    width: 80%;
    position: inherit;
    left: 50%;
    margin-top: 150px;
    transform: translate(-50%, -50%);
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

#Footer-Login {
    padding: 12px 25px !important;
}

.ant-menu-inline-collapsed>.ant-menu-item .anticon {
    color: #ffffff !important;
}

.trigger {
    font-size: 20px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    stroke: #323276;
    stroke-width: 40px;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 55px;
    margin: 8px 8px 5px;
    text-align: center;
    border-bottom: 1px solid #041E42;
}

SVG {
    vertical-align: baseline;
}

.ant-card .card-herder2 .ant-card-body {
    padding: 20px 0px;
}

.ant-card .card-herder3 .ant-card-head {
    color: #041E42;
    background-color: #D8EBE5 !important;
    /* padding-left: 10px !important; */
    font-size: 14px !important;
    padding: 7px 17px 7px 7px;
}

.ant-card.required>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title::after {
    content: " *";
    color: red;
}

.ant-card-head-title {
    padding: 0px;
    color: #041E42;
    font-weight: bold;
}

.ant-card-head {
    padding: 0%;
    min-height: 0px;
}

.ant-btn {
    border: 1px solid #323276;
    color: #323276;
    font-size: 13px;
}

.ant-btn:hover {
    color: #323276;
    border: 1px solid #323276;
    box-sizing: border-box;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 2px 2px 2px rgba(114, 104, 104, 0.05);
    font-size: 13px;
}

.ant-btn:focus {
    color: #323276;
    border: 1px solid #323276;
}

.workLogButton.ant-btn {
    color: #323276;
    margin-right: 10px;
}


/* .workLogButton.ant-btn:focus {
  color: #FA541C;
  border: 1px solid #FA541C;
  margin-right: 10px;
} */

.workLogButton-focus.ant-btn {
    color: #FA541C;
    border: 1px solid #FA541C;
    margin-right: 10px;
}

.workLogButton-active.ant-btn {
    color: #FA541C;
    border: 1px solid #FA541C;
    margin-right: 10px;
}

.ant-btn-primary {
    color: #ffffff !important;
    background-color: #FA541C;
    border-color: #FA541C;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover {
    color: #ffffff !important;
    background-color: #FF7A45;
    border-color: #FF7A45;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:focus {
    color: #ffffff !important;
    background-color: #FA541C;
    border-color: #FA541C;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-background-ghost.ant-btn-primary:hover {
    color: #ffffff !important;
    border-color: #FA541C;
}

.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 0px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
}

.ant-btn-background-ghost.ant-btn-primary {
    color: #ffffff !important;
    background-color: transparent;
    border-color: #323276;
    text-shadow: none;
}

.ant-switch-checked {
    background-color: #FA541C !important;
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    /* line-height: 1.5; */
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    background-color: #d9d9d9;
    border: 1px solid transparent;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.36s;
    transition: all 0.36s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed{
  background: #323276 !important;
  width: 70px !important;
  flex: 0 0 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
} */

#my-legend {
    width: auto;
    margin-left: 10px;
    font-size: 16px;
}

fieldset {
    border: 1px solid #000;
}

.my-custom-class .ant-modal-confirm-btns {
    display: none;
}

.ant-modal-confirm .ant-modal-close {
    display: inline;
}

.ant-layout-sider {
    background: #323276 !important;
}

.ant-menu-inline-collapsed {
    width: auto;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #323276;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    color: #FA541C;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #FA541C;
    border-radius: 5px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-dark.ant-menu-vertical .ant-menu-dark.ant-menu-vertical-left .ant-menu-dark .ant-menu-vertical-right {
    padding-left: 15px;
    padding-right: 15px;
}

.ant-form-item {
    margin-bottom: 5px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: auto;
}

.certain-search-item-count {
    position: absolute;
    color: #999;
}

.certain-category-search.ant-select-focused .certain-category-icon {
    color: #108ee9;
}

.certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
}

.ant-steps-item-process .ant-steps-item-icon {
    background-color: #34547E !important;
}

.myCardSetting {
    padding: 20px;
    margin-bottom: 15px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background-color: #F2F2F2;
    color: #323276;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background-color: #ffffff;
    color: #FA541C;
}

.ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td {
    padding: 2px 13px;
}

.table td {
    vertical-align: inherit !important;
}

.edit_icon,
.del_icon,
.clone_icon,
.scan_icon {
    color: #323276;
    cursor: pointer;
}

.edit_icon:hover,
.del_icon:hover,
.clone_icon:hover {
    color: #FA541C;
    cursor: pointer;
}

.scan_icon {
    color: #323276;
    font-size: 25px;
    cursor: pointer;
}

.scan_icon:hover {
    color: #FA541C;
    font-size: 25px;
    cursor: pointer;
}

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
}

.menu-setting-page {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
}

.logo_setting:hover {
    color: #041E42;
}

.ant-tooltip-inner {
    background-color: #FFF2E8;
    color: #FA541C !important;
}

.ant-radio-inner::after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 8px;
    height: 8px;
    background-color: #FA541C;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' ';
}

.ant-radio-checked .ant-radio-inner {
    border-color: #FA541C;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FA541C;
    border-color: #FA541C;
}

.ant-pagination {
    font-size: 13px;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #FA541C !important;
}

.ant-pagination-item-active a {
    color: #FA541C !important;
}

.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
    color: #FA541C;
    border-color: #FA541C;
    cursor: not-allowed;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: block;
    height: 100%;
    font-size: 12px;
    /* text-align: center; */
    background-color: #fff;
    color: #323276;
    border: 1px solid #323276;
    border-radius: 4px;
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-pagination-item {
    background-color: #fff;
    border: 1px solid #323276;
    color: #323276;
}

.ant-pagination-item:hover {
    background-color: #fff;
    border: 1px solid #FA541C;
    color: #FA541C;
}

.ant-pagination-item a {
    color: #323276;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
    color: #FA541C !important;
}

.ant-layout-footer {
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    background: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #FFD8BF
}

.MyText-form {
    color: #323276;
    font-weight: normal;
    font-size: 13px;
}

.ant-btn-dashed:hover {
    border: 1px solid #FA541C;
    color: #FA541C;
}

.ant-btn-dashed {
    border: none;
    color: #323276;
}

.ant-modal-confirm-btns>.ant-btn:not([disabled]) {
    text-decoration: none;
    color: #323276;
}

.ant-modal-confirm-btns>.ant-btn:not([disabled]):hover {
    text-decoration: none;
    color: #323276;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
}

.ant-modal-confirm-btns>.ant-btn.ant-btn-danger {
    color: #FFFFFF;
    border: 1px solid #FA541C;
    background: #FA541C;
}

.ant-modal-confirm-btns>.ant-btn.ant-btn-danger:hover {
    text-decoration: none;
    background-color: #FA541C;
    color: #fff;
    border: 1px solid #FA541C;
}

.ant-radio-wrapper::after {
    background-color: #323276;
    color: #323276;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner,
.ant-select-selection {
    border-color: #323276;
}

.ant-table-column-title,
.topicName,
.ant-select-dropdown-menu-item,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    color: #323276;
    font-size: 13px;
    font-weight: bold;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-descriptions-title {
    color: #323276;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background-color: #FFF2E8 !important;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #FFD8BF;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #FFD8BF;
}

.ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #323276;
    border-radius: 2px;
    border-collapse: separate;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-checkbox .ant-checkbox-indeterminate {
    background-color: #FA541C;
    color: #FA541C;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #323276;
    border: 1px solid #323276;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border: 1px solid #323276;
}

.ant-table-thead>tr>th {
    padding: 12px 16px;
}

tr.ant-table-expanded-row {
    background-color: #f2f2f2;
}

tr.ant-table-expanded-row:hover {
    background-color: #f2f2f2;
}

tr.table-noherder.ant-table-thead>tr:first-child>th:last-child {
    display: none;
}

.ant-layout.ant-layout-has-sider {
    flex-direction: row;
    height: -webkit-fill-available;
}

.ant-checkbox+span {
    padding-right: 0px;
    padding-left: 8px;
    font-weight: normal !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #FFD8BF
}

.ck-editor__editable_inline {
    min-height: 150px;
}

.tagSLA {
    background-color: #2F80ED;
    color: #fff;
}

.table-config-header thead {
    display: none;
    margin-top: 3px;
}

.sladisplay-stage-complete {
    color: #C5C3C8;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}

.upload-list-inline .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
}

.upload-list-inline .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
}

.ql-editor {
    min-height: 100px !important;
    /* max-height: 20000px; */
    overflow: hidden;
    overflow-y: scroll;
}


/*.activate{*/


/*  display: flex;*/


/*  float: right;*/


/*}*/

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-time-picker-input {
    border-color: #323276;
    border-right-width: 1px !important;
    font-size: 13px;
    color: #323276;
}

.ant-select-selection--multiple,
.ant-select-selection--multiple:hover,
.ant-select-selection--multiple:focus {
    min-height: 32px;
    padding-bottom: 3px;
    cursor: text;
    /* zoom: 1; */
    border: 1px solid #323276;
}

.ant-input-number-input {
    width: 100%;
    height: 30px;
    padding: 0 11px;
    text-align: left;
    background-color: aliceblue;
    border: 1px solid #323276;
    border-radius: 4px;
    outline: 0;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    /* -moz-appearance: textfield !important; */
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover .ant-input:placeholder-shown {
    border-color: #323276;
    border-right-width: 1px !important;
    font-size: 13px;
}

.ant-select-arrow svg {
    display: inline-block;
    color: #323276;
}

.ant-select-disabled .ant-select-selection .ant-select-arrow svg {
    display: inline-block;
    color: inherit !important;
}

.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice .ant-select-selection__choice__content {
    color: #8c8c8c !important;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: #FA541C;
    border-color: #FA541C;
}

.ant-time-picker-input {
    border: 1px solid #323276;
}

.label-requester {
    color: #6A6C8F;
    margin-right: 10px;
}

.selectStyleRed.ant-dropdown-link.ant-dropdown-trigger {
    color: red;
    margin-right: 5px;
    margin-left: 5px;
}

.selectStyleBlack.ant-dropdown-link.ant-dropdown-trigger {
    color: #323276 !important;
    margin-right: 5px;
    margin-left: 5px;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    font-size: 13px;
    margin-top: 2px;
}

.disabledStatus.ant-input[disabled] {
    background: #E0E0E0;
    border-color: #E0E0E0 !important;
    color: #323276 !important;
}

.background-form-criteria {
    background-color: #FFF2E8;
    border: 15px #FFF2E8;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
    column-rule: 10px outset pink;
    position: relative;
    width: 100%;
}

.addCriteriaButton.ant-btn {
    border: 1px solid #FA541C;
    color: #FA541C;
    font-size: 13px;
}

.addCriteriaButton.ant-btn:hover {
    box-shadow: 2px 2px 2px rgba(114, 104, 104, 0.2);
}

.verticalLine {
    border: 0.4px solid #323276;
    height: -webkit-fill-available;
    position: absolute;
    margin-top: 30px;
    background-color: #323276;
}

.requiredIcon {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.deleteButton {
    padding: 10px;
    color: #FA541C;
    border: #FA541C;
    font-size: 13px;
}

.deleteButton:hover {
    color: #FA541C;
    border: #FA541C;
    font-size: 13px;
}

.disabled-icon {
    padding: 10px;
    color: #FA541C;
    font-size: 13px;
    opacity: 0.5;
    pointer-events: none;
}

.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-calendar-picker-icon {
    color: inherit !important;
}

.ant-input-disabled+.ant-calendar-picker-icon {
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 8px 10px;
}

.ant-modal-title {
    color: #323276;
    font-size: 16px;
    font-weight: 500;
}

.border-current-view {
    border: 1px solid #FFD8BF;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 7px;
    /* margin-right: 40px; */
}

.border-height-default {
    min-height: 430px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: #323276;
}

.ant-btn-primary[disabled] {
    color: #A9A9A9 !important;
}

.ant-select.ant-select-disabled>.ant-select-selection.ant-select-selection--single>.ant-select-selection__rendered>.ant-select-selection-selected-value {
    color: #8c8c8c !important
}

.selectBorder.ant-select.ant-select-enabled.ant-select-allow-clear>.ant-select-selection.ant-select-selection--single,
.inputRequire.ant-input:placeholder-shown {
    border-color: #FA541C !important;
}

.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
}

.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
}

.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
}

.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
}

.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
}

.showHoliday {
    border-color: #323276;
    color: #323276;
    width: 100%;
    height: 35px;
    padding: 7px;
}

.disable-showHoliday {
    background-color: #f5f5f5 !important;
    border: none;
    color: #323276;
    width: 100%;
    height: 35px;
    padding: 7px;
}


/*.inputRequire.ant-input:placeholder-shown {*/


/*  border-color: #FA541C!important;*/


/*}*/

.compact-picker {
    width: 515px !important;
}

.customWidth.compact-picker {
    width: 240px !important;
}

.custom-compact-picker.compact-picker {
    width: auto !important;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:hover {
    color: #FA541C;
}

.ant-tabs-ink-bar {
    background-color: #FA541C;
}

.custom-card {
    height: 100%;
}

.custom-card>.ant-card-body {
    width: 100%;
    height: 100%;
}

.custom-position-button {
    position: absolute;
    bottom: 5%;
    right: 1%;
}

@keyframes example {
    from {
        background-color: #fff;
    }

    to {
        background-color: #FFD8BF;
        opacity: 0.75
    }
}

.custom-highlight table>tbody>:first-child {
    color: #323276;
    background-color: #fff;
    animation-name: example;
    animation-duration: 1.5s;
}

.icon {
    width: 30px;
    height: 30px;
}

.Box-DashBoard {
    border: '1px solid #E0E0E0';
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.TopicDashboard {
    background-color: #E1ECF5;
    padding: 10px;
}

.TextFrontDesc {
    color: #344054;
    font-size: 14px;
    font-weight: bold;
}

.fix_scroll_md {
    min-width: '768px';
    overflow: auto;
}

.Desc_text_gray {
    /* margin-left: 9%; */
    font-size: 13px;
    color: #757F8D;
}

.Desc_text_gray_2 {
    color: #323276;
}

.BG_Gray {
    background-color: #F2F2F2;
    padding: 10px;
}

.BG_orange {
    background-color: #FFF2E8;
    padding: 10px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: 300px;
}

.ant-modal-header {
    border-bottom: 0px;
}

.FontTest {
    font-family: "Times New Roman", Times, serif;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fa541c;
    background: #fff;
    border-color: #fa541c;
    -webkit-box-shadow: -1px 0 0 0 #fa541c;
    box-shadow: -1px 0 0 0 #fa541c;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #fa541c;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: #fa541c;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #fa541c !important;
    opacity: 0.1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fa541c;
    border-color: #fa541c;
    -webkit-box-shadow: -1px 0 0 0 #fa541c;
    box-shadow: -1px 0 0 0 #fa541c;
}

.selectTimeFilterINC .ant-select-selection__clear {
    display: none;
}

.ant-dropdown-menu {
    max-height: 50vh;
    overflow: auto;
}

@media (max-width: 480px) {
    .ant-calendar-range {
        width: 250px;
    }

    .ant-calendar-range-part {
        width: 100%;
    }
}

.satButton:hover {
    opacity: 0.5;
}

.DoneLayout {
    text-align: center;
    margin-top: 30vh;
}

.feck-link {
    color: #2f80ed;
    cursor: pointer;
}

.ant-comment-avatar img {
    width: inherit !important;
    height: inherit !important;
    border-radius: inherit !important;
}

.validateArrowNumber .ant-input-number-handler-wrap {
    display: none;
}

.ant-menu-submenu-title .anticon+span {
    opacity: 1;
    color: #ffffff !important;
    -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #323276;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 45%) inset;
    box-shadow: 0 2px 8px rgb(0 0 0 / 45%) inset;
}

.step-content {
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.card-title .ant-card-head-title {
    padding: 10px;
    color: #041E42;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
}

.card-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-align-center {
    font-family: 'Sarabun', sans-serif;
    text-align: center;
}

.text-align-start {
    font-family: 'Sarabun', sans-serif;
    text-align: start;
}

.text-align-justify {
    font-family: 'Sarabun', sans-serif;
    text-align: justify;
}

.CardSmallNoPadding>.ant-card-body {
    padding: 0px !important;
}

.AvatarGroup {
    display: flex;
    list-style-type: none;
    flex-direction: row;
}

.Avatars {
    border-radius: 100%;
    color: #ffffff;
    display: block;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 100;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    transition: margin 0.1s ease-in-out;
    overflow: hidden;
    margin-left: -10px;
}

.Table-CustomField .ant-table-tbody>tr>td {
    padding: 8px 10px 16px 10px;
}

.Table-CustomField .has-error .ant-form-explain {
    font-size: 10px;
    position: absolute;
}

.Box-Chat {
    border: 1px solid #323276;
    border-radius: 6px;
    min-height: 350px;
    max-height: 500px;
    overflow-y: scroll;
    padding-bottom: 15px;
}

.Box-Chat::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
}


/* Track */

.Box-Chat::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.Box-Chat::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.Box-Chat::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}

.Chat-User {
    background: #FFFFFF;
    border: 1px solid #323276;
    padding: 5px 10px;
    margin: 5px;
    max-width: 500px;
    word-wrap: break-word;
    border-radius: 10px 10px 5px;
    width: auto;
}

.Chat-GPT {
    background: #FFFFFF;
    border: 1px solid #323276;
    padding: 5px 10px;
    margin: 5px;
    word-wrap: break-word;
    max-width: 500px;
    border-radius: 5px 10px 10px;
    width: auto;
}

.TicketRelateCard {
    background-color: #F2F2F2;
    padding: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.TicketRelateCard-DeleteButton {
    width: 24px;
    height: 24px;
    background-color: #ffffff;
    border: 1px solid #E0E0E0;
    text-align: center;
}

.feck-link-Button {
    color: #2f80ed;
    cursor: pointer;
    border: none;
    box-shadow: none;
}

.feck-link-Button:disabled,
.feck-link-Button:disabled:hover {
    cursor: not-allowed;
    border: none;
    box-shadow: none;
    background-color: #ffffff;
}

.text-login {
    font-size: 13px;
}

.background-right-form-login {
    position: absolute;
    right: 0%;
    width: 20%;
}

.background-left-form-login {
    position: absolute;
    left: 0%;
    width: 15%;
}

.background-bottom-right-form-login {
    position: absolute;
    right: 0%;
    width: 8%;
    bottom: 0;
}

.background-bottom-left-form-login {
    position: absolute;
    left: 0%;
    width: 20%;
    bottom: 0;
}

.inputLogin.ant-input-affix-wrapper .ant-input-prefix {
    left: 3%;
}

.miniLogoMobile {
    background-color: #323276;
    text-align: center;
    width: 64px;
    height: 64px;
}

.ant-table-pagination.ant-pagination {
    float: right;
    margin: 16px 0;
    white-space: break-spaces;
}

.TicketRelateCard-DeleteButton:hover {
    width: 24px;
    height: 24px;
    color: #fa541c;
    border: 1px solid #fa541c;
    text-align: center;
}

.image-container {
    /* object-fit: cover;  */
    width: auto;
    height: 5cm;
    padding: 8px;
}

.custom-table-tranfer-card {
    padding: 5px;
    border-radius: 5px;
    /* width: 48%; */
}

.tab-margin-custom>.ant-tabs-bar {
    margin: 0px !important;
}

.custom-select .ant-select-selection__choice__remove {
    display: none !important;
    pointer-events: none;
}

.overflow-table-herder .ant-table-content {
    overflow: auto;
}

.max-width150 {
    max-width: 150px !important;
}

.Item-Notify {
    cursor: pointer;
    width: 450px;
    padding-right: 0px;
}

.Item-Notify:hover {
    cursor: pointer;
    background-color: rgba(104, 101, 101, 0.25);
}

.Notify {
    padding: 10px;
    width: 480px;
    max-height: 400px !important;
    overflow-y: scroll;
    overflow-x: unset;
    word-break: break-all !important;
}

.Notify::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
}

/* Track */
.Notify::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

/* Handle */
.Notify::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

/* Handle on hover */
.Notify::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}

.modal-not-show-close>.ant-modal-content>.ant-modal-close {
    display: none;
}

.modal-not-show-close>.ant-modal-body.ant-modal-confirm-body-wrapper>.ant-modal-confirm-btns {
    float: right;
    margin-top: 12px;
}

.force-break-word {
    max-width: 320px;
    word-break: break-all !important;
    font-size: 14px;
    white-space: initial !important;
}

.ant-tabs-tab-disabled {
    color: #bdbdbd !important;
}

button:disabled,
button[disabled] {
    color: #bdbdbd !important;
}

.notify-icon {
    color: #323276;
}

.notify-icon:hover {
    color: #fa541c;
}

.custom-tooltip {
    color: #fa541c !important;
    /* background-color: #f79574; */
}

.Option-Icons {
    font-size: 20px;
    font-weight: 50;
    color: rgb(0, 0, 0);
    background-color: #E5E5E5;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    padding-top: 0px;
    padding-bottom: 0px;
}

.Box-Scroll-Templete {
    max-height: 480px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Box-Scroll-Templete::-webkit-scrollbar {
    width: 10px;
    border-radius: 6px;
}


/* Track */

.Box-Scroll-Templete::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.Box-Scroll-Templete::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.Box-Scroll-Templete::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}

.box-word-warp {
    text-wrap: balance;
}

.box-home {
    padding-left: 4%;
    background-color: rgba(242, 242, 242, 0.8);
    border: '1px solid #E0E0E0';
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 15px;
    margin-left: 4%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    width: 90%;
}

.MyFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
}

.content-woSign {
    padding: 24px;
    background-color: #fff;
    width: 100%;
}

.table-dataJobSign {
    width: 100%;
    border-collapse: collapse;
}

.content-dataJobSign {
    border-bottom: 1px solid #ddd;
    /* padding-top: 12px; */
    padding-left: 16px;
}

.content-info {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 12px;
}

.custom-input-wo .ant-input-clear-icon {
    display: none;
}

.custom-input-wo:hover .ant-input-clear-icon,
.custom-input-wo:focus .ant-input-clear-icon,
.custom-input-wo .ant-input-clear-icon-hover {
    display: inline-flex
}

.model-success-mark-none>.ant-modal-content>.ant-modal-close {
    display: none;
}

.model-success-mark-none .ant-modal-confirm-body>.anticon {
    font-size: 25px;
    padding-top: 4px;
}

.custom-formLabel-wo .ant-form-item-label>label {
    font-weight: bold;
    font-size: 14px;
    color: #344054;
}

.custom-formLabel-wo .ant-form-item-label>label.ant-form-item-required::before {
    font-weight: normal;
}

.bold-titleWo {
    font-weight: bold;
    color: #323276;
}

.main-title-orange {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FA541C;
}

.custom-modal-wo .ant-modal-confirm-body>.anticon {
    font-size: 25px;
    padding-top: 4px;
}

.custom-modal-wo>.ant-modal-content>.ant-modal-close {
    display: none;
}

.text-herder-Orenge {
    color: #FA541C !important;
    font-size: 18px !important;
    font-weight: bold;
}

.text-herder {
    color: #000;
    font-size: 18px;
    font-weight: bold;
}

.common-font-topic {
    font-size: 16px;
    font-weight: 600;
}

.text-details {
    color: #475467;
    font-size: 16px;
    font-weight: bold;
}

.icon-setting {
    font-size: 26px;
    color: #2f80ed;
}

.btn-setting {
    border: 1px solid #D0D5DD;
    border-radius: 15px;
}

.btn-setting:hover {
    border: 1px solid #2f80ed;
    color: #2f80ed !important;
}

.box-priority {
    padding: 20px;
    max-height: 600px;
    overflow-x: auto;
    border: 1px solid #D0D5DD;
    border-radius: 15px;

}

.box-priority::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
}


/* Track */

.box-priority::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.box-priority::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.box-priority::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}
.customfield-pm-group-container {
    border: 1px solid #D0D5DD;
    background-color: #F8F8F9;
    padding: 20px;
    border-radius: 7px;
    margin: 10px 0px;
}

.text-underline {
    text-decoration: underline;
    cursor: pointer;
}

.text-underline:hover {
    text-decoration: underline;
    cursor: pointer;
    color: #FA541C;
}

.delete-filter-box {
    border: 2px solid #D0D5DD;
    border-radius: 7px;
    padding: 10px;
}

.checkbox-choice-box {
    background-color: #F8F8F9;
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
}

.delete-filter-header {
    font-size: 14px;
    margin: 5px;
    font-weight: bold;
}

.breadcrumb-delete-filter>span>.ant-breadcrumb-link,
.breadcrumb-delete-filter>span>.ant-breadcrumb-separator {
    color: #323276;
    cursor: pointer;
}

.breadcrumb-delete-filter>span>.ant-breadcrumb-link:hover {
    color: #FA541C;
}

.select-no-border {
    width: auto;
    outline: none;
}

.select-no-border>.ant-select-selection,
.select-no-border>.ant-select-selection:focus {
    border: none;
    box-shadow: none;
    padding-right: 5px;
    margin-left: -5px;
}

.select-dropdown-no-border {
    width: auto !important;
}

.image-view-box {
    border: 1px solid #E0E0E0;
    padding: 5px;
    cursor: pointer;
    min-height: 135px;
    display: flex;
    align-content: center;
    justify-content: center;
}

.image-view-text-name {
    text-align: center;
    max-width: 85px;
    margin-top: 3px;
    word-wrap: break-word;
}

.Box-Picture>.ant-row {
    margin: 0 !important;
}

.image-view-box:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.ant-menu-item-group-title {
    padding: 0px;
    font-weight: 600;
    color: #323276;
    font-size: 14px;
    line-height: 1.5;
    transition: all 0.3s;
}

.problemas-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #EEEEFF !important;
    color: #323276 !important;
    border-right: 5px solid #000080 !important;
}

.Table-no-background .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background-color: #FFFFFF;
}

.problem-update-sidebar {
    max-height: 535px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 10px;
}

.problem-update-sidebar::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
}


/* Track */

.problem-update-sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.problem-update-sidebar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.problem-update-sidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}
.Table-View-CustomField .ant-table-tbody>tr>td {
    padding: 15px 8px;
}

.custom-menu-settingList {
    padding: 8px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
}

.custom-menu-settingList:hover, .custom-menu-settingList:active, .custom-menu-settingList:focus {
    background-color: #EBEEF3;
    border: none;
}

.List-Comment-Problem {
    max-height: 240px;
    overflow: auto;
}

.List-Comment-Problem::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
}


/* Track */

.List-Comment-Problem::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.List-Comment-Problem::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.List-Comment-Problem::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}

.Table-View-CustomField .ant-table-tbody>tr>td {
    padding: 15px 8px;
}

/* chat bubble */
#chat-popup {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.chat-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
}

#open-chat-btn {
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

#ProblemsManagemantWrapped_SearchTopicAndTicket {
    width: 350px;
}

.display-scrollbar-height500 {
    max-height: 475px;
    overflow: auto;
}

.display-scrollbar-height500::-webkit-scrollbar {
    width: 5px;
    border-radius: 6px;
}


/* Track */

.display-scrollbar-height500::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}


/* Handle */

.display-scrollbar-height500::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}


/* Handle on hover */

.display-scrollbar-height500::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}

.ant-pagination-item-link>i,
.ant-steps-icon>i {
    margin-left: 0px;
}

@media (max-width: 480px) {
    .ant-select-selection>.ant-select-selection__clear>i {
        margin-left: 0px;
    }

}

.dashboard-containner{
    border-radius: 10px; 
    border: 1px solid #ECEDED;
    background-color: #F9FAFC;
    padding: 20px;
    margin: 10px 0px;
}

.error-fields-woSetting .ant-form-explain, .error-fields-assetSetting .ant-form-explain {
    position: absolute;
}

.btn-pm-approve:hover {
    background-color: #59598F !important;
    border-color: #59598F !important;
}

.overflow-table-herder .ant-table-body::-webkit-scrollbar,
.overflow-table-herder .ant-table-content::-webkit-scrollbar {
    padding-top: 10px;
    height: 5px;
    border-radius: 6px;
}

.overflow-table-herder .ant-table-body::-webkit-scrollbar-track,
.overflow-table-herder .ant-table-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

.overflow-table-herder .ant-table-body::-webkit-scrollbar-thumb,
.overflow-table-herder .ant-table-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
}

.overflow-table-herder .ant-table-body::-webkit-scrollbar-thumb:hover,
.overflow-table-herder .ant-table-content::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 6px;
}
