    .StyleCollapse {
        max-width: 85vw;
        margin: 0 auto;
        overflow: hidden;
    }
    .IconExport {
        text-align: end;
        margin-top: -40px;
        margin-left: 50vw; 
    }
    .ReportTable {
        white-space: nowrap;
        margin: 0 auto;
    }
    .ant-table-empty .ant-table-body {
        overflow-x: unset !important;
        overflow-y: unset !important;
    }
@media only screen and (min-width: 841px) and (max-width: 1040px){
    .ReportTable {
        max-width: 78vw;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 681px) and (max-width: 840px){
    .ReportTable {
        max-width: 73vw;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 480px) and (max-width: 680px){
    .ReportTable {
        max-width: 67vw;
        margin: 0 auto;
    }
    .IconExport{
        margin: 0 auto;
        position: relative;
        text-align: start;
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
    }
      
    .ant-picker-panels > *:last-child {
        display: none;
    }
      
    .ant-picker-panel-container, .ant-picker-footer {
        width: 280px !important;
    }
      
    .ant-picker-footer-extra > div {
        flex-wrap: wrap !important; 
    }

}
@media only screen and (min-width: 519px) and (max-width: 562px){
    .ant-dropdown-link{
        display: none;
    }
}
@media only screen and (max-width: 519px){
    .anticon{
        margin-left: 3vw;
    }
    .ant-dropdown-link{
        display: none;
    }
    .logo{
        /* margin-left: 20vw; */
        margin-left: 5%;
    }
    .logo img{
        height: 30px;
    } 
    .logo h6{
        display: none;
    }
    .ant-menu-item-icon{
        margin-left: 20vw;
    } 
    .IconExport{
        position: relative;
        text-align: start;
        margin: 0 auto;
    }
    .inside{
        display: flex;
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
    }
    .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
    }
      
    .ant-picker-panels > *:last-child {
        display: none;
    }
      
    .ant-picker-panel-container, .ant-picker-footer {
        width: 280px !important;
    }
      
    .ant-picker-footer-extra > div {
        flex-wrap: wrap !important; 
    }
}