.Header {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #323276;
}

.Text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #323276;
}

.ant-dropdown-menu {
    max-height: 90vh;
    top: 16px;
}

.profile-show {
    width: 240px;
}
