.ant-card-bordered {
    border: 2px solid #ebebeb;
}

/*.ant-card-head-title {*/
    /*background-color: #f5f5f5;*/
/*}*/

#bg-gray {
    background-color: #323276!important;
    min-height: 100%;
}

.card-title .ant-card-head-title {
    color: #323276;
}

#logo {
    text-align: center;
    margin-top: 10%;
}

#box-step {
    background-color: #ffffff;
    padding: 5%;
    border: 1px solid #C4C4C4;
    border-radius: 7px;
    min-height: 300px;
}

#topic {
    /* font-family: 'Sarabun'; */
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    color: #323276;
}

#sub-title {
    /* font-family: 'Sarabun'; */
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #323276;
}

#content {
    /* font-family: 'Sarabun'; */
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #4A4A4A;
}
